import {FC, useEffect, useRef} from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/img/logo.png';
import {NavLink} from "react-router-dom";
import ROUTES from "../../const/ROUTES";


const Header: FC = () => {
    const textRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
            setTimeout(() => {
                if(textRef){
                    textRef?.current?.classList.remove(styles.hidden);
                }
            },500);
    }, [])

    return (
        <>
        <div className={styles.wrapper}>
            <a id="logo"
               title="demenz-betreuungszeit.de">
                <img src={logo} alt={"Logo Demenz Betreuungszeit Andrea Hiensch Sunten"} />
            </a>
            <ul>
                <li>
                    <NavLink to={ROUTES.motivation}>
                        Motivation
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.leadingThoughts}>
                        Leitgedanke
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.concept}>
                        Konzept
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.conditions}>
                        Konditionen
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.about}>
                        Über mich
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.newCareGrades2017}>
                        Neue Pflegegrade ab 2017
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.usefulLinks}>
                        Nützliche Links
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ROUTES.contact}>
                        Kontakt
                        <span className={'activeLinkDecorator'}></span>
                    </NavLink>
                </li>
            </ul>
        </div>
            <div className={styles.banner}>
                <ul ref={textRef} className={`${styles.hidden} ${styles.flyInText}`}>
                    <li>D</li>
                    <li>e</li>
                    <li>m</li>
                    <li>e</li>
                    <li>n</li>
                    <li>z</li>
                    <li>-</li>
                    <li>B</li>
                    <li>e</li>
                    <li>t</li>
                    <li>r</li>
                    <li>e</li>
                    <li>u</li>
                    <li>u</li>
                    <li>n</li>
                    <li>g</li>
                    <li>s</li>
                    <li>z</li>
                    <li>e</li>
                    <li>i</li>
                    <li>t</li>
                </ul>
            </div>
        </>
    )
}

export default Header;