import {FC} from 'react';
import styles from './About.module.scss';
import TextBlock from "../../components/TextBlock";


const About: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Über mich'}>
                <p>Meine Name ist Andrea Hiensch-Sunten, ich wurde am 14.07.1963 in Wanne-Eickel geboren und bin seit
                    1990 verheiratet.
                    Mein privater Lebensplan wurde mit der Geburt meiner Söhne 1991 und 1995 zu 100% erfüllt.
                    1984 erwarb ich durch das staatliche Examen am Ev. Krankenhaus Mülheim an der Ruhr die
                    Berufsbezeichnung „examinierte Krankenschwester“
                    In diesem Beruf war ich tätig ……..
                </p>
                <div className={styles.timeLineWrapper}>
                    <ul className={styles.timeLine}>
                        <li>
                            <p className={styles.time}>1984-1989</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.text}>auf einer Unfallchirgischen Station</p>
                        </li>
                        <li>
                            <p className={styles.text}>auf einer Gerontopsychiatrischen Station als stellvertretende Stationsleitung</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.time}>1989-1994</p>
                        </li>
                        <li>
                            <p className={styles.time}>1994-1996</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.text}>in der ambulanten/häuslichen Krankenpflege in Teilzeit</p>
                        </li>
                        <li>
                            <p className={styles.text}>vollständige Pflegeübernahme meiner an Demenz erkrankten Großmutter</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.time}>1998-2001</p>
                        </li>
                        <li>
                            <p className={styles.time}>2002-2011</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.text}>in der ambulanten/häuslichen Krankenpflege</p>
                        </li>
                        <li>
                            <p className={styles.text}>Weiterbildung zum &quot;Wundexperten ICW&quot; Regelmäßige Teilnahme an Fortbildungen : u.a. Reanimation, onkologische Pflege, Kinästhetik</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.time}>2009</p>
                        </li>
                        <li>
                            <p className={styles.time}>2012-2014</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.text}>Ausbildung zur Fachangestellten für Medien-und Informationsdienste Fachbereich medizinische Dokumentation mit erfolgreichem Abschluss vor der Industrie- und Handelskammer</p>
                        </li>
                        <li>
                            <p className={styles.text}>Berufsbegleitendes einjähriges Praktikum in der onkologischen Kinderklinik des Uniklinikum</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.time}>2013</p>
                        </li>
                        <li>
                            <p className={styles.time}>Seit 2014</p>
                            <div className={styles.timeLineDivider}></div>
                            <p className={styles.text}>selbstständig mit Demenz-Betreuungszeit</p>
                        </li>
                    </ul>
                </div>

            </TextBlock>
        </div>
    )
}

export default About;