const ROUTES = {
    about: 'ueber-mich',
    contact: 'kontakt',
    concept: 'konzept',
    home: '/',
    motivation: 'motivation',
    usefulLinks: 'nuetzliche-links',
    imprint: 'impressum',
    conditions: 'konditionen',
    newCareGrades2017: 'neue-pflegegrade-seit-2017',
    dataProtection: 'datenschutz',
    leadingThoughts: 'leitgedanke',
}

export default ROUTES;