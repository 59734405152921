import {FC} from 'react';
import styles from './LeadingThoughts.module.scss';
import TextBlock from "../../components/TextBlock";


const LeadingThoughts: FC= () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Leitgedanke'}>
                <p>
                    <span className={'boldLetter'}>F</span>
                    ür die Aufnahme meiner Selbstständigkeit mit der Betreuung von Senioren, demenzkranken Menschen oder Menschen mit Einschränkungen in ihrer Alltagskompetenz, haben meine persönlichen Beweggründe eine große Rolle gespielt.
                    Eigenverantwortlich und selbstständig zu arbeiten bedeutet für mich: verantwortungsvoll, würdevoll, empathisch, respektvoll, vertrauensvoll und geduldig mit den sich mir anvertrauenden Menschen umzugehen und vorrangig ihren Bedürfnissen zu folgen. Senioren, oder besonders dementziell veränderte Menschen brauchen deutlich mehr Zuwendung und Zeit und werden durch Zeitdruck eher verunsichert.
                </p>
                <p>
                    Heute ist es durch die minutiös geplanten Einsätze der Pflegekräfte leider nicht immer möglich, die benötigte Zeit für Zuwendung aufzubringen.
                    Unglücklich sind damit sicher nicht nur Betroffene, sondern auch die Pflegekräfte, die jeden Tag durch viel persönliches Engagement versuchen, die bestmögliche Betreuung eines Patienten zu ermöglichen.
                </p>
                <p>
                    Ich möchte Ihnen diese Zeit geben und für Sie als Betroffene/r eine verlässliche Begleiterin sein, die Ihnen hilft Sicherheit und Selbstständigkeit wieder zu gewinnen oder weiter zu erhalten. Ziel soll sein, Ihnen  weiterhin ein selbstbestimmtes Leben mit viel Freude, Spaß und Abwechslung zu ermöglichen.  Alt sein heißt nicht, sich den eventuell körperlichen oder geistigen Einschränkungen hingeben zu müssen. Nach vorne schauen, positiv den Tag beginnen und mit einer helfenden Hand den Tag oder ein paar schöne Stunden so zu gestalten wie man es möchte wird auch für Sie eine enorme Bereicherung sein.
                </p>
                <p>
                    Angehörigen möchte ich eine zuverlässige und entlastende Unterstützung anbieten.
                </p>
            </TextBlock>
        </div>
    )
}

export default LeadingThoughts;