import {FC} from 'react';
import styles from './Contact.module.scss';


const Contact: FC= () => {
    return (
        <div className={styles.wrapper}>
            <h1>Kontakt</h1>
            <p>Andrea Hiensch-Sunten Demenz-Betreuungszeit</p>
            <p>Dimbeck 1</p>
            <p>45470 Mülheim an der Ruhr</p>
            <p>Tel: <a className={'bold'} href={'tel:+4917623915940'}>+49 176 23915940</a></p>
            <p>E-Mail: <a className={'bold'}  href={'mailto:info@demenz-betreuungszeit.de'}>info@demenz-betreuungszeit.de</a></p>
        </div>
    )
}

export default Contact;