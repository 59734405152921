import {FC} from 'react';
import styles from './NewCareGrades.module.scss';
import TextBlock from "../../components/TextBlock";


const NewCareGrades: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Neue Pflegegrade ab 2017'}>
                <p><span className={'boldLetter'}>M</span>it Inkrafttreten des zweiten Pflegestärkungsgesetzes am 1. Januar 2016 wurde der Begriff der Pflegebedürftigkeit dann noch einmal überarbeitet und ab dem 1. Januar 2017 die 3 Pflegestufen durch 5 Pflegegrade abgelöst.
                    Dies ermöglicht nun allen Pflegebedürftigen gleichberechtigten Zugang zu den Leistungen der Pflegeversicherung, unabhängig davon, ob sie von körperlichen, geistigen oder psychischen Beeinträchtigungen betroffen sind. Die Überleitung in die neuen Pflegegrade erfolgt automatisch.
                </p>
            </TextBlock>
        </div>
    )
}

export default NewCareGrades;