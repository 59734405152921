import {FC} from 'react';
import styles from './Motivation.module.scss';
import TextBlock from "../../components/TextBlock";


const Motivation: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Motivation'}>
                <p>
                    <span className={'boldLetter'}>M</span>it dem Demographischen Wandel und der Zunahme an Lebensjahren steigt auch die Anzahl derjenigen, die an Demenz oder Alzheimer erkranken.
                    Schon seit Jahren machen Mediziner, Pflegekräfte und Wissenschaftler auf dieses Thema aufmerksam und stellen es in den Fokus öffentlicher Diskussionen, um so immer wieder auf Probleme im Bereich der häuslichen Pflege und Betreuung von Menschen mit Demenz aufmerksam zu machen.
                    Ziel dieser Transparenz sollte sein, Menschen, die an Demenz erkrankt sind und deren Angehörige, die die Herausforderung der Pflege und Betreuung von Betroffenen übernommen haben, zu unterstützen oder zu begleiten.
                    Um dem Wunsch eines geliebten Angehörigen nach einem
                    <br />
                        <span className={'italic lh-5'}>
                        &quot;Altern in Würde im gewohnten Umfeld&quot;
                        </span>
                    <br />
                    nachkommen zu können, gehen Partner, Töchter und Söhne häufig weit über die Grenzen ihrer Belastbarkeit.
                    Durch die zusätzliche Aufgabe der Betreuung und Pflege des Erkrankten, brechen Familienstrukturen auseinander und oft fehlt die Zeit den eigenen Interessen, Hobbys oder gar seiner eigenen Berufstätigkeit nachgehen zu können. &quot;Sich einfach mal ausruhen und Kraft tanken&quot; wird zudem als Zeitverlust für &quot;wichtigere Dinge&quot; erachtet.
                    <br />
                </p>
                <p>
                    Erst wenn Ungeduld und manchmal auch Aggression als Zeichen der eigenen Erschöpfung den Ablauf in der Betreuung und Pflege deutlich beeinträchtigen, greifen Angehörige nach helfenden Händen.
                    Nur selten und natürlicherweise ungern gestehen sich Angehörige vor der ersten Erschöpfungsphase ein, Haushalt, Familie und die Pflege eines Angehörigen nicht auf Dauer alleine bewältigen zu können.
                </p>
            </TextBlock>
        </div>
    )
}

export default Motivation;