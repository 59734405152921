import {FC} from 'react';
import styles from './UsefullLinks.module.scss';
import TextBlock from "../../components/TextBlock";


const UsefulLinks: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Nützliche Links'}>
                <ul>
                    <li>
                        <a href="https://www.pflege-durch-angehoerige.de/pflegegrade-pflegeleistungen/pflegesachleistungen/"
                           target="blank"
                        >
                            Was Sie über Pflegeleistungen wissen sollten
                        </a>
                    </li>
                    <li>
                        <a href="http://www.pflegestaerkungsgesetz.de/download/pflegegesetz/BMGs_Broschuere_Leistung_Web.pdf"
                           target="blank">
                            Leistungen2017
                        </a>
                    </li>
                    <li>
                        <a href="http://dzd.blog.uni-wh.de/grundlagen-der-demenz-18-informationsblaetter-zum-downloaden/#more-12728"
                           target="blank"
                        >
                            Basiswissen Demenz
                        </a>
                    </li>
                    <li>
                        <a href="http://www.pflegestaerkungsgesetz.de/pflege-wissen-von-a-bis-z/pflege-details/erklaerung/pflegegrade-neuer-pflegebeduerftigkeitsbegriff/"
                           target="blank"
                        >
                            Das Pflegestärkungsgesetz
                        </a>
                    </li>
                    <li>
                        <a href="http://www.muelheim-ruhr.de/cms/pflegestuetzpunkte.html"
                           target="blank"
                        >
                            Pflegestützpunkte Mülheim an der Ruhr
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.deutsche-alzheimer.de/unser-service/alzheimer-gesellschaften-und-anlaufstellen/alzheimer-gesellschaften-und-anlaufstellen/plz/4.html"
                            target="blank"
                        >
                            Alzheimer Gesellschaften
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://dzd.blog.uni-wh.de/dzd-auftrag/"
                            target="blank"
                        >
                            Dialog- und Transferzentrum Demenz
                        </a>
                    </li>
                    <li>
                        <a
                            href="http://www.kv-media.de/pflegegrade-2017.php"
                            target="blank"
                        >
                            Pflegegrade 2017
                        </a>
                    </li>
                    <li>
                        <a href="https://www.dzla.de/">
                            Dialogzentrum im Alter
                        </a>
                    </li>
                </ul>
            </TextBlock>
        </div>
    )
}

export default UsefulLinks;