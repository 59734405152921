import {FC} from 'react';
import styles from './DataProtection.module.scss';


const DataProtection: FC = () => {
    return (
        <div className={styles.wrapper}>
                <h1>Datenschutzerklärung</h1>
                <h2>1. Datenschutz auf einen Blick</h2>
                <h3>Allgemeine Hinweise</h3>
                <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
                    Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten
                    sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen
                    zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </p>

                <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
                <h3>Datenschutz</h3> <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
                    ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                    Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p> <p>Wenn Sie diese Website benutzen,
                    werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
                    persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten
                    wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                    geschieht.</p> <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                    Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
                    Zugriff durch Dritte ist nicht möglich.</p>
                <h3>Hinweis zur verantwortlichen Stelle</h3> <p>Die verantwortliche Stelle für die Datenverarbeitung auf
                    dieser Website ist:</p> <p> Andrea Hiensch-Sunten<br/>
                    Dimbeck 1 45470<br/>
                    Mülheim an der Ruhr</p>

                <p>Telefon: 017623915940<br/>
                    E-Mail: info@demenz-betreuungszeit.de</p>
                <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
                    anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                    E-Mail-Adressen o. Ä.) entscheidet.</p>
                <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3> <p>Viele Datenverarbeitungsvorgänge sind nur
                    mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
                    jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
                    bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3> <p>Im Falle datenschutzrechtlicher
                    Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                    Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte
                    des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
                    sowie deren Kontaktdaten können folgendem Link entnommen werden:
                <a rel="noreferrer"
                   href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                   target="_blank">
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                </a>.
                </p>
                <h3>Recht auf Datenübertragbarkeit</h3> <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                    Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
                    Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
                    Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
                    technisch machbar ist.</p>
                <h3>SSL- bzw. TLS-Verschlüsselung</h3> <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als
                    Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
                    Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem
                    Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
                    können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                <h3>Auskunft, Sperrung, Löschung</h3> <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                    jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
                    deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
                    Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                    personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
                    wenden.</p>
                <h3>Widerspruch gegen Werbe-Mails</h3> <p>Der Nutzung von im Rahmen der Impressumspflicht
                    veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
                    ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa
                    durch Spam-E-Mails, vor.</p>
                <h2>3. Datenerfassung auf unserer Website</h2>
                <h3>Cookies</h3> <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
                    Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
                    nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf
                    Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p> <p>Die meisten der von uns
                    verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs
                    automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen.
                    Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p> <p>Sie
                    können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                    Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
                    der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>

                <h3>Server-Log-Dateien</h3> <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in
                    so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                    <li>IP-Adresse</li>
                </ul>
        </div>
    )
}

export default DataProtection;