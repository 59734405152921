import React from 'react';
import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {Route, Routes} from "react-router-dom";
import ROUTES from "./const/ROUTES";
import About from "./pages/About";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";
import Contact from "./pages/Contact";
import Conditions from "./pages/Conditions";
import Concept from "./pages/Concept";
import LeadingThoughts from "./pages/LeadingThoughts";
import Motivation from "./pages/Motivation";
import UsefulLinks from "./pages/UsefulLinks";
import NewCareGrades from "./pages/NewCareGrades";

function App() {
  return (
    <div className="App">
      <Header/>
        <div className="contentWrapper">
            <Routes>
                <Route path={ROUTES.about} element={<About />} />
                <Route path={ROUTES.concept} element={<Concept />} />
                <Route path={ROUTES.conditions} element={<Conditions />} />
                <Route path={ROUTES.contact} element={<Contact />} />
                <Route path={ROUTES.dataProtection} element={<DataProtection />} />
                <Route path={ROUTES.home} element={<Motivation />} />
                <Route path={ROUTES.imprint} element={<Imprint />} />
                <Route path={ROUTES.leadingThoughts} element={<LeadingThoughts />} />
                <Route path={ROUTES.motivation} element={<Motivation />} />
                <Route path={ROUTES.newCareGrades2017} element={<NewCareGrades />} />
                <Route path={ROUTES.usefulLinks} element={<UsefulLinks />} />
            </Routes>
        </div>
      <Footer/>
    </div>
  );
}

export default App;
