import {FC} from 'react';
import styles from './Footer.module.scss';
import {NavLink} from "react-router-dom";
import ROUTES from "../../const/ROUTES";


const Footer:FC = () => {
    return (
        <div className={styles.wrapper} id={'Footer'}>
            <div className={`${styles.content} ${styles.spacer}`}>

            </div>
            <div className={styles.content}>
                <h4>Rechtliche Hinweise</h4>
                <ul className={styles.linklist}>
                    <li>
                        <NavLink to={ROUTES.dataProtection}>
                            Datenschutz
                            <span className={'activeLinkDecorator'}></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.imprint}>
                            Impressum
                            <span className={'activeLinkDecorator'}></span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className={styles.content}>
                <h4>Informationen</h4>
                <ul className={styles.linklist}>
                    <li>
                        <NavLink to={ROUTES.usefulLinks}>
                            Nützliche Links
                            <span className={'activeLinkDecorator'}></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.about}>
                            Über mich
                            <span className={'activeLinkDecorator'}></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={ROUTES.contact}>
                            Kontakt
                            <span className={'activeLinkDecorator'}></span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className={styles.content}>

            </div>
        </div>
    )
}

export default Footer;