import {FC} from 'react';
import styles from './Concept.module.scss';
import TextBlock from "../../components/TextBlock";


const Concept: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Konzept'}>
                <p>
                    <span className={'boldLetter'}>I</span>ch betreue, begleite, fördere und unterhalte Senioren, an Demenz Erkrankte, Menschen mit
                    Einschränkungen der Alltagskompetenz, mit Behinderungen, immobile Menschen oder Senioren, die sich
                    von Zeit zu Zeit Ansprache wünschen, weil sie keine Angehörigen haben.
                </p>
                <p>
                    Mein Angebot beinhaltet sowohl die Förderung der Bewegungsfähigkeit und Gedächtnisleistung wie
                    unterhaltsame Gespräche oder Unternehmungen, die Ihnen Spaß und Freude bereiten.
                </p>
                <p>
                    Mein Ziel ist es, Ihnen, unter Nutzen der noch vorhandenen Ressourcen, ein selbstbestimmtes Leben zu
                    Hause zu ermöglichen.
                </p>
                <p>
                    Senioren, die an Demenz erkrankt sind oder Menschen, die aufgrund einer Behinderung auf Hilfe
                    angewiesen sind, entwickeln oftmals ein Schuldgefühl, wenn sie ihre Partner oder Kinder für die
                    Pflege oder Unterstützung im Alltag in Anspruch nehmen müssen.
                    Betreuenden Angehörigen hingegen rennt die Zeit häufig davon und Sie leiden darunter nicht allen
                    Seiten gerecht werden zu können.
                    Hier ist es für beide Seiten gut, wenn zumindest stundenweise Hilfe von außen kommt.
                </p>
                <h4>Ich habe Zeit für Dinge die Ihnen wichtig sind!</h4>
                <p>
                    <span className={'boldLetter'}>D</span>enn oft sind es ja auch die einfachen und unspektakulären Dinge bei denen man eine helfende Hand benötigt und die dann ein Lächeln auf ein Gesicht zaubern. Falls Sie an einer individuellen Betreuung interessiert sein sollten, finden Sie hier ein paar Beispiele, der von mir angebotenen Tätigkeiten
                </p>

                <div className={styles.boxWrapper}>
                    <div className={styles.box}>
                        <h3>Begleitung</h3>
                        <ul>
                            <li>beim Einkauf</li>
                            <li>beim Spaziergang</li>
                            <li>beim Spaziergang</li>
                            <li>um Arzt</li>
                            <li>zu Verwandten, Freunden</li>
                            <li>zum Gottesdienst</li>
                            <li>zum Sport oder Physiotherapeuten </li>
                            <li>zum Gottesdienst </li>
                            <li>ins Cafe oder Restaurant</li>
                            <li>ins Theater oder Konzert</li>
                            <li>oder...</li>
                        </ul>
                    </div>

                    <div className={styles.box}>
                        <h3>Unterstützung</h3>
                        <ul>
                            <li> bei der Verrichtung alltäglicher Arbeiten (Körperpflege, Nahrungsaufnahme) </li>
                            <li>Unterstützung zur Teilnahme an einem soziokulturellen Leben</li>
                            <li>bei Antragstellungen oder dem Schreiben von Briefen</li>
                            <li>bei der Einhaltung von Terminen</li>
                            <li>usw...</li>
                        </ul>
                    </div>

                    <div className={styles.box}>
                        <h3>Gedächtnistraining</h3>
                        <ul>
                            <li>durch Wortspiele und Quizfragen</li>
                            <li>durch Biographie Arbeit (erzählen von früher, anschauen von Bildern oder selbstgedrehten Filmen)</li>
                            <li>durch tägliches Vorlesen aus der Tagespresse oder dem Lieblingsbuch</li>
                            <li>durch das Notieren wichtiger Informationen oder Termine in einem Kalender</li>
                            <li>
                                <b>Einprägen (Automatisieren) immer wiederkehrender Vorgänge</b><br/><br/> durch
                                wiederholtes gemeinsames Ablaufen wichtiger Wegstrecken
                                Verrichten immer wiederkehrender Tätigkeiten z.B. bei der Körper- und Mundpflege
                            </li>
                        </ul>
                    </div>

                    <div className={styles.box}>
                        <h3>Sicherheit</h3>
                        <ul>
                            <li>durch Sturzprophylaxe und-prävention (vermeiden von Stolperfallen etc.)</li>
                            <li>durch Einhalten von zeitlich festgelegten Abläufen</li>
                            <li>(z.B. erinnern an die Medikamenteneinnahme)</li>
                            <li>durch Förderung der Beweglichkeit</li>
                            <li>(was zur Stärkung des Immunsystems, zu Muskelaufbau und Verbesserung des Gleichgewichtes beiträgt]</li>
                        </ul>
                    </div>
                </div>

            </TextBlock>
        </div>
    )
}

export default Concept;