import {FC, PropsWithChildren} from 'react';
import styles from './TextBlock.module.scss';
import dividerImage from "../../assets/img/divider.png";


export interface ITextBlockProps {
    heading: string;
}

const TextBlock: FC<PropsWithChildren<ITextBlockProps>> = ({heading, children}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                <h1>{heading}</h1>
                <img src={dividerImage} alt="Trennlinie Bild"/>
            </div>
            {children}
        </div>
    )
}

export default TextBlock;