import {FC} from 'react';
import styles from './Conditions.module.scss';
import TextBlock from "../../components/TextBlock";


const Conditions: FC = () => {
    return (
        <div className={styles.wrapper}>
            <TextBlock heading={'Konditionen'}>
                <p>
                    <span className={'boldLetter'}>Ü</span>ber Ihr Interesse an meinem Betreuungsangebot freue ich mich sehr.
                </p>
                <p>
                    Gerne komme ich zu einem unverbindlichen und kostenlosen Erstgespräch zu Ihnen nach Hause.
                    Sie als Betroffene/r oder pflegender Angehörige erzählen mir von Ihren Betreuungswünschen und wenn
                    Sie mögen, von Ihren Vorlieben oder Gewohnheiten.
                    So lernen wir uns schon ein bisschen kennen und bei beidseitiger Sympathie vereinbaren wir einen
                    Betreuungsplan der für Sie hilfreich ist.
                    Egal ob Sie mich für eine Einzelstunde oder mehrere Stunden buchen, einmal, mehrmals wöchentlich
                    oder täglich.
                    Wir finden gemeinsam eine optimale Lösung um die Zeit sinnvoll und gewinnbringend für Sie aber auch
                    finanzierbar zu gestalten.
                    Bitte informieren Sie sich über die ab Januar 2017 deutlich verbesserten Leistungen der
                    Pflegekassen,wenn Sie Betreuungsleistungen für einen an Demenz erkrankten Angehörigen in Anspruch
                    nehmen müssen.
                </p>
                <p>
                    Als Betreuungseinheit zähle ich immer eine ganze Stunde. Ich bin erreichbar Montag bis Donnerstag von
                    8:00 Uhr bis 17 Uhr.
                    Betreuungen gestalte ich nach Absprache und Ihren Betreuungswünschen.
                </p>
                <p>
                    Die Preise richten sich danach ob ich an einem Werktag , Wochenend- oder Feiertag betreue.
                    Grundsätzlich betreue ich nur von Mo-Freitags. Nur in Ausnahmefällen nach vorheriger Absprache helfe
                    ich auch an WE. oder Feiertagen.
                </p>
                <p>
                    Für jede Anfahrt berechne ich eine Pauschale von 4,00 €, Alle weiteren Kosten für Anfahrt oder Fahrten zu Ausflügen, Einkäufen usw. berechne ich mit 0,50 Cent pro gefahrenen Kilometer.
                </p>
            </TextBlock>
        </div>
    )
}

export default Conditions;